@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,700,1,200');


body{
  padding: 0;margin: 0;
  /* font-family: 'DM Sans', sans-serif; */
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;
}
header{
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0) 0px 1px 1px, rgba(0, 0, 0, 0) 0px 2px 2px, rgba(0, 0, 0, 0) 0px 4px 4px, rgba(0, 0, 0, 0.02) 0px 8px 8px, rgba(0, 0, 0, 0.02) 0px 16px 16px;
  z-index: 99;
}
.banner-top{
  padding-top: 110px;
}
.navbar-brand img{
  width: 260px;
  transition: 1s;
}
.navbar-brand.active img{
  width: 200px !important;
  transition: 1s;
}

.banner-content h1{font-size: 5.4rem;font-weight: 800;max-width: 80%;margin: auto !important;}
 p{font-size: 18px;color: #6B6B6B;font-weight: 500;}
.btn-primary{background: #2766EB !important;border-radius: 25px;padding: 10px 25px; font-size: 14px; font-weight: 400;position: relative;transition: .5s ease-in;border-color: #2766EB;color: #fff;}

.btn-primary:hover{box-shadow: inset 24.5em 0 0 0 #fff ,inset 16.5em 0 0 0 #fff; color: #0059D1;}
.btn-primary::before,.btn-primary::after{position: absolute;content: "";z-index: -1;}
.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  transition: .2s ease-in;
  color: #fff !important;
}
.banner-wrapper{     background-image: url(./Assets/Images/banner-background.jpg); background-size: cover;width: 100%;}
.banner-content small{color: #2486FC;letter-spacing: 3px;font-size: 20px;font-weight: 500;}
.banner-content p{font-size: 23px; max-width: 885px;margin: auto;}
.left-avatar{position: absolute;left: 0;top: 0;height: 130px;}
.banner-input{display: flex;justify-content: center;position: relative;}
.banner-input input{border: 1px solid #CDF;padding: 11px 18px;border-radius: 25px;}
.banner-input .material-symbols-outlined{font-weight: 600;font-size: 20px;}
.right-avatar{position: absolute; top: 0;right: 0;height: 130px;}
.files-section{padding-top: 30px;}
.service-blocker{margin-top: 40px;}
.project-card{border: 1px solid #E8EFFF; background: #ECF1FF;padding: 25px;border-radius: 22px;max-height: 747px;overflow: hidden;}
.inner-tips p{padding: 8px 15px; background: #fff;border-radius: 20px;line-height: 25px;display: inline-block;}
.inner-tips .material-symbols-outlined{color: #2766EB;font-weight: 500;vertical-align: middle;padding-bottom: 1px;}
.project-card h2{font-size: 45px;font-weight: 600;}
.project-types{background: #fff;border-radius: 12px; padding: 20px;margin-right:-40px;}
.project-types h5{font-weight: 600;}
.date-info{color:#6B6B6B;font-size: 14px;font-weight: 500;}
.banner-heading span{position: relative;}
.ring-img{position: absolute; top: 0;right: 0;}
.client-service-card{border-radius: 20px;background: #FDF7DF;padding: 20px;max-height: 367px;overflow: hidden;}
.avatar-teamates{border-radius: 20px;background: #EBFAF8;padding: 20px;max-height: 367px;overflow: hidden;}
.bussiness-blocker{margin-top: 90px;}
.bussiness-blocker p{line-height: 33px;}
.bussiness-blocker h2{font-size: 46px;font-weight: 600;}
.features-blocker{margin-top: 90px;margin-bottom: 220px;}
/* .features-background{background-image: url(./Assets/Images/projectflow.png);background-size: 100% !important;background-size: cover; height: 669px;background-repeat: no-repeat;border-radius: 30px;} */
.features-text{padding: 35px;text-align: center;color: #333;}
.features-text h2{color: #123888;margin: auto;padding-bottom: 10px;font-weight: 600;font-size: 45px; }
.features-text h4{
  color: #484848;
  font-size: 22px;
  padding-bottom: 11px;
}
/* .features-text p{color: #fff;max-width: 600px;margin:auto;} */
.features-text .worrk-flow {display: flex; flex-wrap: wrap;justify-content: center;}
.features-text .worrk-flow p{
  font-size: 16px;
  font-weight: 400;
  margin-right: 11px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #123888;
  padding: 5px 16px;
  border-radius: 10px;
  border: 1px solid #6478a2;
}
.features-text .worrk-flow p span{font-size: 18px;}

/* .features-text .worrk-flow .cor-a span{color: #1949b0;}
.features-text .worrk-flow .cor-b span{color: #a87f13;}
.features-text .worrk-flow .cor-c span{color: #b92323;}
.features-text .worrk-flow .cor-d span{color: #7a9d18;}
.features-text .worrk-flow .cor-e span{color: #9423bd;}
.features-text .worrk-flow .cor-f span{color: #1d898e;}
.features-text .worrk-flow .cor-g span{color: #8e6c51;}
.features-text .worrk-flow .cor-h span{color: #197597;}
.features-text .worrk-flow .cor-i span{color: #6f2f2f;}
.features-text .worrk-flow .cor-j span{color: #2e9d24;}
.features-text .worrk-flow .cor-k span{color: #1949b0;}
.features-text .worrk-flow .cor-a{color: #2766eb; } 
.features-text .worrk-flow .cor-b{color: #e6a70a; } 
.features-text .worrk-flow .cor-c{color: #eb2727; } 
.features-text .worrk-flow .cor-d{color: #7a9d18; } 
.features-text .worrk-flow .cor-e{color: #b727eb; } 
.features-text .worrk-flow .cor-f{color: #1daeb5; } 
.features-text .worrk-flow .cor-g{color: #d2a887; } 
.features-text .worrk-flow .cor-h{color: #1698c8; } 
.features-text .worrk-flow .cor-i{color: #6f2f2f; } 
.features-text .worrk-flow .cor-j{color: #2fb923; } 
.features-text .worrk-flow .cor-k{color: #2766eb; } 
.features-text .worrk-flow .cor-l{color: #333; } */
.product img
{
  color: #fff;
  font-size: 13px;
  font-weight: 200;
  margin: 0 9px 0 0;
  width: 20px;
}
.pld, .product p
{
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  margin: 0;
}
.pld
{
  text-align: end;
}
footer {background-color: #000;}
.footer-banner {background-color: #fff;border-radius: 30px;display: flex;justify-content: center;align-items: center;position: relative;overflow: hidden; margin-top: -135px;margin-bottom: 25px;
  /* height: 250px; */
  box-shadow: -4px 8px 40px -2px rgba(6, 12, 59, 0.12); }
.banner-a {position: absolute;left: 0;top: 0;width: 108px; }
.banner-b {position: absolute;right: 0;bottom: 0; width: 160px; }
 ul{list-style: none;}
.footer-box-align h6{color:rgba(217, 145, 0, 0.83);}
/* .footer-menu{display: flex;justify-content: end;list-style: none;margin: 0;padding: 0;} */
.footer-icons ul{padding: 0;}
.footer-menu-polices{display: flex;justify-content: start;list-style: none;margin: 0;padding: 0;}
footer li a{color: #fff;font-size: 14px;font-weight: 300; }
  /* footer li a:hover{color: #fff; border-bottom: 2px solid #fff;padding: 5px 0px;} */
a{text-decoration: none;}
.bottom-menu{border-bottom: 1px solid #5E5E5E;}
.modal-backgrnd{ background-image: url(./Assets/Images/modalbackgrd.jpg); background-size: cover;height: 255px; background-repeat: no-repeat; width: 100%;}
.demo-modal .modal-header{padding: 0;}
.demo-modal .modal-header a{display: flex;justify-content: end; padding: 10px !important;}
.demo-modal .modal-header .material-symbols-outlined {font-variation-settings:'FILL' 1,'wght' 700,'GRAD' 0, 'opsz' 48 !important;font-weight: 900;font-size: 28px;cursor: pointer;}
.demo-modal h4{font-size: 21px;font-weight: 600;}
.demo-modal p{font-size: 16px;}
.demo-modal.modal-content{border-radius: 25px; overflow: hidden;border:none}
.modal-content{border:none !important;overflow: hidden !important;border-radius: 25px;}
.error {color: #f60909!important;width: 100%;display: block;text-align: left;padding-top: 5px;font-size: 14px;padding-left: 23px;}
.custom-field{display: block;}
.custom-field .placeholder {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 23px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition:top 0.3s ease,color 0.3s ease,font-size 0.3s ease;}
.custom-field input.dirty + .placeholder,.custom-field textarea.dirty + .placeholder { top: -10px;font-size: 10px;color: #222;}
.custom-field.one input{ border: 1px solid #CDF; padding: 11px 18px;border-radius: 25px; transition: border-color 0.3s ease;}
.custom-field.one textarea{border: 1px solid #CDF; padding: 11px 18px;border-radius: 25px; transition: border-color 0.3s ease;}
.custom-field.one input + .placeholder,.custom-field.one textarea + .placeholder {left: 8px;padding: 0px 15px}
.custom-field.one input:focus,.custom-field.one textarea:focus { border-color: #2486FC;transition-delay: 0.2s}
.custom-field input:focus,.custom-field textarea:focus{box-shadow: none !important;}
.custom-field.one input:not(:placeholder-shown) + .placeholder,
.custom-field.one textarea:not(:placeholder-shown) + .placeholder,
.custom-field.one input:focus + .placeholder,
.custom-field.one textarea:focus + .placeholder {top: 0;font-size: 11px;color: #222; background: #fff; padding: 0 5px; margin-left: 10px;}
.demo-modal .modal-footer{border-top: none;padding: 0px 0px 30px 0px;}
.demo-modal .modal-footer span{display: inline-block;vertical-align: middle;}
.graph-body{background: #FFEBD4;max-height: 490px; overflow: hidden;padding: 120px 11px 0px 40px;border-radius: 20px; border: 1px solid #FFEBD4;}
.notification-body{background: #E8EFFF;;max-height: 490px;overflow: hidden; padding: 20px 20px 20px; border-radius: 20px;border: 1px solid #E8EFFF;margin-top: 20px;}
 footer h2{font-size: 46px;font-weight: 600;}
.project-card img{width: 230%;}
.modal-body h4,.modal-body p{text-align: center;}
footer a:hover {
  color: #d7e7ff;
  /* text-decoration: underline; */
}
.bottom-menu-icons{cursor: pointer;}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

.tracking-in-contract{animation:tracking-in-contract .8s cubic-bezier(.215,.61,.355,1.000) both}


 @keyframes tracking-in-contract{0%{letter-spacing:1em;opacity:0}40%{opacity:.6}100%{letter-spacing:normal;opacity:1}}


.nav-link.active{color: #0059D1 !important;font-weight: 500;}

.btn-primary:hover {background-color: #1b5fc1 !important;border-color: #1354b1;}
.footer-logo img{width: 50% !important;}
.modal-header{border-bottom: none;}

/*----- index page style ends -----*/

/*----- solutions page style start------*/


.solution-banner-content h1{font-size: 4.5rem;font-weight: 600;}
.solution-banner-content p{max-width: 560px;font-size: 23px;}
.btn-outline-primary{border-color: #2766EB !important;border-radius: 25px;padding: 10px 25px; font-size: 14px; font-weight: 400;position: relative;transition: .5s ease-in;}
.btn-outline-primary::before,.btn-outline-primary::after{position: absolute;content: "";z-index: -1;}
.btn-outline-primary:hover{box-shadow: inset -10.5em 0 0 0 #348fff  ,inset 10.5em 0 0 0 #348fff ;color: #fff;}
.subscription-types .material-symbols-outlined {font-variation-settings:'FILL' 1,'wght' 700,'GRAD' 0, 'opsz' 48 !important;font-weight: 900;font-size: 22px;color: #2766EB;}
.subscription-types ul{padding: 0;display: flex;justify-content: space-between;}
.subscription-types ul li{font-size: 13px;display: flex; align-items: center;}
.get-started{margin-top: 60px;}
.get-started .card-body{border-radius: 30px;background: #F3F5F7;}
.get-started p{color: #000;}
.get-started .card-body h2{font-size: 2.6rem;font-weight: 600;}
.get-started .inner-tips p{color: #2766EB;font-size: 14px;font-weight: 600;}
.project-flow img{width: 85% !important;}
.project-flow{text-align: center;}
.inner-num{background: #fff;border-radius: 50%;height: 37px;width: 40px;display: inline-block;padding-top: 6px;color: #2766EB;font-weight: 500;box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.10);}
.project-content{text-align: center;padding-top: 20px;}
.project-content h6{font-size: 1.2rem;font-weight: 600;}
.main-features{margin-top: 50px;}
.main-features .inner-tips p{background: #EFF7F2; color: #257B43;font-weight: 600;font-size: 14px;}
.main-features .inner-tips .material-symbols-outlined{color: #257B43;}

.main-features-content h2{font-size: 2.6rem;font-weight: 600;}

.main-features-content{text-align: center;}
.main-features-content p{max-width: 700px;margin: auto;}
.main-features-blocker h3{font-size: 2.5rem;font-weight: 600;max-width: 535px;}
.main-features-blocker p{max-width: 525px;line-height: 32px;}
.timeline li p{display: flex;align-items: center;margin: 0;}
.timeline .material-symbols-outlined{color: #2486FC;font-weight: 600;}
.promotion-blocker{margin-top: 50px;}
.promotion-body{background-image: url(./Assets/Images/promotion-backgrnd.jpg); background-size: cover;height: 360px; background-repeat: no-repeat; width: 100%;border-radius: 25px;}
.promotion-body h3{font-size: 2.6rem;font-weight: 600;max-width: 590px;}
.promotion-body p{max-width: 545px;}
.support-blocker{margin-top: 15px; margin-bottom: 195px;}


/*----- solutions page style ends------*/

/*----- pricing page style starts------*/

/* .pricing-banner-wrapper{margin-top: 70px;} */
.plan-type-align{
  padding: 0px 40px;
} 

.border-paid{
  border-right: 1px solid #CFDFFF;
}

.month-plane-p{
  font-size: 15px;
  font-weight: 400;
  color: #909090;
}
.done-item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.done-item span{
  color: #4B81F3;
  font-size: 19px;
  margin-right: 9px;
}
.done-item p{
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  color: #333;
  text-align: left;
}
.top-style{
  padding-top: 37px;
}
.enterprise-p{
  color: #0059D1;
  font-size: 17px;
  font-weight: 500;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}
.listing-style{
  height: 26px;
}

.recmd-btn{
background-color: #f8f9fa;
  border-color: #4B81F3;
  border-radius: 30px;
  padding: 5px 30px;
  display: flex;
  align-items: center;
}
.recmd-btn:hover
{
  background-color: #F5F8FF;
  border-color: #4B81F3;}
.pricing-bg{background-image: url(./Assets/Images/pricing-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  display: flex;
  align-items: first baseline;
  position: relative;
  object-fit: cover;
  padding-top: 80px;}

  .pricingimgb.img-fluid{position: absolute;
    right: 125px;
    top: 150px;
  width: 310px;
}
.pricingimga.img-fluid{
  position: absolute;
  left: 80px;
  bottom: 30px;
  width: 310px;
}

.pricing-p.py-2{
  width: 735px;
  font-size: 19px;
}
.plans{
  display: flex;
  justify-content: center;
  text-align: center;
}
.plans-content
{
  font-size: 16px;
  font-weight: 400;
  color: #333;
  height: 86px;
  margin-bottom: 0;
}
.pricing-banner-content h1{font-size: 4.5rem;font-weight: 600;max-width: 588px;}
.pricing-banner-content p{max-width: 404px;font-size: 23px;}
.plantypes-content{text-align: center;}
.plantypes-content h2{font-size: 65px;font-weight: 600;}
.plantypes-content p{margin: auto; color: #909090;}
.normal-plans .card-body h5{font-size: 2.2rem;font-weight: 500;}
.normal-plans .card-body h6{font-size: 2.1rem;color: #0059D1;font-weight: 500;}
.check-items .material-symbols-outlined{color:#4B81F3;}
.normal-plans{border-radius: 24px;border: 1px solid #DEE9FF;background: #FFF;box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.13);}
.check-items p{display: flex;align-items: center; color: #909090;}
.pro-plans .card-body h5{font-size: 2.2rem;font-weight: 500;color: #fff;}
.pro-plans .card-body h6{font-size: 2.1rem;color: #fff;font-weight: 500;}
.pro-plans{border-radius: 24px; background: #2766EB; box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.17);}
.pro-plans p,.pro-plans .material-symbols-outlined{color: #fff;}
.pro-plans .btn-outline-primary{background: #fff;}
/* .pro-plans .btn-outline-primary:hover{color: #2766EB;} */
.btn-outline-primary:hover {background: #2766EB !important; color: #fff !important;border: 1px solid #fff !important;}
.recomnd-plan{background: #fff;display: inline-block;border-radius: 20px;padding: 5px 15px;height: 35px;vertical-align: middle;}
.recomnd-plan span{color:#4B81F3;font-weight: 500;}
.normal-plans p,.pro-plans p{font-size: 19px;letter-spacing: .2px;font-weight: 400;}
.animation-plan{height: 80px;}
.plan-divider{margin-top: 70px;margin-bottom: 105px;}
.plan-divider-text{text-align: center;margin-bottom: 20px;}
.plan-divider-text h2{font-size: 2.6rem;font-weight: 600;}
.plan-divider-text p{max-width: 900px;margin: auto;}
.table { width: 100%; border-collapse: collapse;}
.table td,
.table th { padding: 10px 0px;text-align: center;font-size: 16px;text-align: start;font-weight: 400;}
.table th {background-color: #fff;color: black;}
.table th:first-child {color: #A4A3A3}
tbody, td, tfoot, th, thead, tr{ border-bottom: 1.353px solid #DCE6FF;}
.table > :not(:last-child) > :last-child > * {border-bottom-color: #DCE6FF;}
.table .material-symbols-outlined{color: #2766EB;font-weight: 500;vertical-align: middle;}
.pro-row{background: #F2F6FF !important;}
 th.pro-row{border-top-left-radius: 8px;border-top-right-radius: 8px;}
.grecaptcha-badge{z-index: 1;}


/*responsive*/

@media (max-width: 767px) {
  .table thead {display: none;}
  .table,
  .table tbody,
  .table tr,
  .table td {display: block;width: 100%;}
  .table tr { margin-bottom: 15px;}
  .table td {padding-left: 50%;text-align: left;}
  .table td::before {content: attr(data-label);width: 50%;padding-left: 10px;font-size: 15px;font-weight: bold;text-align: left;}
  td{position: relative;}
  td span{position: absolute;left: 50%;}
}


.primary
{
    height: 41px;
    margin: auto;
    border-radius: 20px;
    width: 310px;
    border-radius: 40px;
    height: 64px;
    display: flex;
    background: #E9F0FF;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

}
.primary label{
  display: flex;
  justify-content: center;
}

.switch{
  margin-left: 7px;
  }
  .switch span.right {
  left: 52% !important;
  border-radius: 10px;
  transition: all 200ms ease;
  border-radius: 23px;
  width: 45%;
  }
  .switch label{
  z-index: 99 !important;
  }
  
  .switch {
    min-width: 300px;
    display: inline-block;
    border-radius: 10px;
    height: 50px;
    padding: 2px;
    position: relative;
    zoom: 1;
  }
  .switch:before, .switch:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  /* .switch:after {
    clear: both;
  } */
  .switch label {
    float: left;
    width: 50%;
    position: relative;
    z-index: 2;
    line-height: 40px;
    cursor: pointer;
    color: #4B81F3;
    border-radius: 10px;
    padding-top: 4px;
    
    
  }
  .switch input[type="radio"] {
    display: none;
  }
  /* .switch input[type="radio"]:checked + label {
    color: white;
    font-weight: bold;
  } */
  .switch label.select  {
    color: white;
    font-weight: bold;
  }
  .switch span {
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    display: block;
    border-radius: 23px;
    color: white ;
    background-color: #4B81F3;
    transition: all 200ms ease;
  }
  .switch span.right {
    left: 50%;
    border-radius: 10px;
    transition: all 200ms ease;
    border-radius: 23px;
  }
  
  .switch label {
    float: left;
    width: 50%;
    position: relative;
    z-index: 2;
    line-height: 40px;
    cursor: pointer;
    color: #4B81F3;
    border-radius: 10px;
    padding-top: 4px;
  }
  
  .content{
      display: flex;
      justify-content: center;
      margin-top: 90px;
      text-align: center;
  }
  


/*----- pricing page style ends------*/


/*----- contact page style start------*/

  /* .contact-banner-wrapper{margin-top: 70px;} */
  .contact-banner-content h1{font-size: 4.5rem;font-weight: 600;max-width: 558px;}
  .contact-banner-content p{max-width: 420px; font-size: 23px;}
  .contact-blocker .card-body{border-radius: 12px;border: 1px solid #D4E4FE;position: relative;padding: 40px;text-align: center;margin-bottom: 70px;}
  .contact-blocker .minus-middle{display: inline-grid;padding: 12px;box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);position: absolute;top: -25px;right: 50%;background: #fff;border-radius: 10px;transform: translateX(50%)}
  .contact-blocker .card-body h4{font-size: 1.7rem;font-weight: 700;}
  .contact-blocker .minus-middle .material-symbols-outlined{color: #0059D1;font-weight: 500;}

  .contact-blocker{margin-top: 90px; padding: 40px 0px; background-image: url(./Assets/Images/bg-insight.jpg);
    background-size: cover; background-repeat: no-repeat;}
  
  .email-body{ box-shadow: 0px 0px 33px -2px rgba(0, 0, 0, 0.12);border: none !important;}
  .contact-info a{border-radius: 10px;}
  .form-body{border-radius: 24px;border: 1px solid #DEE9FF;background: #FFF;box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.13);padding: 30px;text-align: center;}
  .form-img{width: 80%;}
  .form-body h3{font-size: 2.2rem;font-weight: 700;}
  .question-blocker{margin-top: 80px;padding-bottom: 200px;}
  .question-head h2{font-size: 2.5rem;font-weight: 700;}
  .question-head p{max-width: 700px;margin: auto;}
  .question-blocker h5{font-size: 1.3rem;font-weight: 500;}
  .question-body p{font-size: 16px;max-width: 360px;}

  .load-btn button{z-index: 1;}
  .question-head{margin-bottom: 60px;}
  .question-body{margin-bottom: 40px;}

.minus-middle span{animation: shake 2s infinite;}
@keyframes shake{
  10%{transform:  scale(1.05) rotate(15deg);}
  20%{transform:  scale(1.05) rotate(-15deg);}
  30%{transform:  scale(1.05) rotate(15deg);}
  40%{transform: none;}
}
.contact-blocker .card-body:hover {transform: scale(1.03);}
.contact-blocker .card-body {transition: transform 0.3s ease-in-out;}
.sel-box{border: none;
  background-color: none;
  padding-right: 20px;
  width: 84px;
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-select{
  background-position: right 0px center;
}
  .select-blocker{position: absolute;top: 5px;left: 8px;}
  .select-body .placeholder{left: 108px !important;}
  .form-select:focus {
    box-shadow: none !important}
  /* .form-select{background-image: none} */
 .select-body input{padding: 11px 25px 11px 120px !important;}
 .bottom-menu-icons{cursor: pointer;}

/*----- contact page style ends------*/



/*----footer----*/
footer{
  background-color: #000;
}
.footer-banner h2{
  font-size: 51px;
  padding-bottom: 15px;
  padding-top: 13px;
}
.footer-banner {
  background-color: #fff;
  border-radius: 30px;
  padding: 48px 189px;
  position: relative;
  overflow: hidden;
  margin-top: -178px;
  margin-bottom: 50px;
}
.footer-banner h6{
  font-size: 24px;
  font-weight: 500;
  background: linear-gradient(90.09deg, #2486FC 4.66%, #DB36AD 97.69%), #88B0FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.footer-menu {
  color: #fff;
}

.footer-menu h6{
  font-size: 15px;
  line-height: 35px;
  font-weight: 500;
  color: #E5E5E5;

}
.footer-menu h5{
font-weight: 500;
font-size: 17px;
line-height: 29px;}

.footer-menu ul{
  padding: 0px;
} 
.footer-menu ul li {
  list-style: none;
  font-size: 14px;
padding-bottom: 15px;
font-weight: 400;

} 

.footer-menu ul li a{
  color: #E5E5E5;
  text-decoration: none;
}
.footer-menu ul li a:hover{
  color: #88B0FF;
}
.footer-line {
  border-bottom: 1px solid #393939;
}
footer h2 span
{
  color: #0072ff;
}
.plan-type-align h3{
  color: #0d6efd !important;
  font-size: 44px;
}
.plan-type-align h2{
  font-size: 30px;
}
.plan-type-align ul li p strong{
  color: #000;
  font-size: 16px;
  font-weight: 600; 
}

.pdt-icons img{
  width: 65px;
}
.pdt-icons .sdklogo{
  width: 100px;
}



.wrapper {
  text-transform: uppercase;
  color: #a5a5a5;
  font-size: 15px;
  padding: 0px 3px;
  position: relative;
  text-align: center;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
  }
  
  .wrapper .tooltip {
    background: #fff;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 5px 15px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      width: 200px;
  color: #333;
  border-radius: 5px;

  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  }
  

  
  .wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .wrapper .tooltip {
  display: none;
  }
  
  .lte8 .wrapper:hover .tooltip {
  display: block;
  }

  .plans-include {
    background-color: #729ad733;
  padding: 40px 0px;
  }

.plans-include .done-item {
  height: 23px;
}







/*------ responsive --------*/

/* 
@media screen and (min-width: 1520px) and (max-width: 1600px) {
  .banner-content h1 {max-width: 75%;}

} */
@media (min-width: 1366px){
  .plantypes-blocker .container-fluid{
    display: flex;
    justify-content: center;
  }
  .plans{
      width: 91%;
    }
  
}






@media (max-width: 1562.98px){
  .plan-type-align{ padding: 0px 25px;}
  .top-style{padding-top: 37px !important;}
}



@media (max-width: 1399.98px){
  .plan-type-align{
    padding: 0px 15px;}
  .pricingimgb.img-fluid{
  position: absolute;
  right: 25px;
  top: 137px;}
  .pricingimga.img-fluid{
  position: absolute;
  left: 18px;
  bottom: 65px;
}
  .plan-type-align h2{
  font-size: 25px;
}
.plans-content{
  font-size: 14px;
}
.enterprise-p
{
    color: #0059D1;
  }
  
  .banner-content h1 {font-size: 70px;max-width: 77%;}
  .ring-img{height: 105px;}
  .bussiness-blocker h2 { font-size: 40px;}
  .project-card h2 {font-size: 40px;}
  .graph-body { max-height: 455px;}
  .notification-body {max-height: 455px;}
  .project-card { max-height: 710px;}
  .bussiness-blocker {margin-top: 35px;}
   h2 { font-size: 40px;}
  .features-text h2 {padding-bottom: 10px;}
  /* .features-background {height: 560px;} */
  .footer-logo img {width: 70% !important; }

/*------ solutions page start -----*/


.solution-banner-content h1 {font-size: 3.5rem;}
.subscription-types ul li {font-size: 10px;}
.plantypes-content p {max-width: 85%;}
.recomnd-plan span {font-size: 14px;}

/*------ solutions page end ------*/

/*------- pricing page start-------*/


.pricing-banner-content h1 {font-size: 3.5rem;}
.normal-plans .card-body h5,.pro-plans .card-body h5{ font-size: 1.8rem;}
.normal-plans p, .pro-plans p {font-size: 18px;letter-spacing: 0px};
/* .primary{width: 25%;} */

/*------- pricing page page end ------*/

/*------- contact page start ----------*/

.contact-banner-content h1{font-size: 3.5rem;}
.contact-blocker .card-body h4 {font-size: 1.5rem;}
.form-body h3 {font-size: 2rem;}

/*------- contact page ends ----------*/

}

@media (max-width: 1299.98px){
  .plan-type-align{
    padding: 0px 9px;}
  .pricingimgb.img-fluid{
    position: absolute;
    right: 25px;
    top: 137px;
    width: 204px;
  }
  .pricingimga.img-fluid{
      position: absolute;
      left: 18px;
      bottom: 65px;
      width: 205px;
    }
    .plans-content{
        font-size: 15px;
      }
}

@media (max-width: 1199.98px){
 
.pricingimga.img-fluid{
      position: absolute;
      left: 18px;
      bottom: 20px;
      width: 205px;
}
.pricingimgb.img-fluid{
    position: absolute;
    right: 25px;
    top: 165px;
    width: 204px;
  }
  .plantypes-content h2{
  font-size: 40px;
  font-weight: 600;
}
.pricing-bg{
  height: 340px;}
.pricing-p.py-2
{
  width: 735px;
  font-size: 16px;}
  .btn-primary:hover
{
  box-shadow: inset 354.5em 0 0 0 #fff ,inset 16.5em 0 0 0 #fff;}
  .border-paid{
  border-bottom: 1px solid #CFDFFF47;
  margin-bottom: 30px;
  border-right: none;
}
  .plan-type-align ul{
    padding: 0;
  }
  .plan-type-align ul li{
    display: flex;
  justify-content: center;
  }

  .graph-body {
  padding: 190px 11px 0px 40px;
  }
  .footer-banner h6{font-size: 33px;}
  .footer-banner h2
  {
    font-size: 40px;
  }
  .footer-banner h6
  {
      font-size: 23px;
    }
  .banner-content h1 {font-size: 60px;max-width: 70%;}
  .ring-img { height: 88px;}
  .right-avatar {height: 100px;}
  .left-avatar{height: 100px;}
  .project-card h2 {font-size: 35px;}
  .bussiness-blocker h2 {font-size: 35px;}
  .project-card {max-height: 674px;}
  .inner-tips p{font-size: 16px;}
   footer h2 {font-size: 35px;}
 
  .features-text { padding: 23px;}
  .features-text h2 { font-size: 35px;}
   p{font-size: 17px;}
  .features-text p {max-width: 475px;}
  .bussiness-blocker p {line-height: 28px;}
  /* .features-background {height: 495px;} */
  .project-card img {width: 295%;}
  .banner-content p {font-size: 21px; }

/*------- solutions page start -------*/

.solution-banner-content h1 {font-size: 3rem;}
.get-started p {  font-size: 15px;}
.main-features-blocker h3 {font-size: 2.3rem;max-width: 425px;}
.promotion-body h3{font-size: 2.3rem;}
.promotion-body{height: 290px;}
.footer-banner {margin-top: -120px;}
.support-blocker { margin-bottom: 165px;}
.solution-banner-content p { font-size: 21px;}

/*------- solutions page end --------*/

/*------- pricing page page start-------*/

.pricing-banner-content h1 {font-size: 3rem;}
.pricing-banner-content p {max-width: 300px;font-size: 21px;}
.plantypes-content p {max-width: 100%;}
.normal-plans .card-body h5, .pro-plans .card-body h5 {font-size: 1.7rem;}
.normal-plans .card-body h6,.pro-plans .card-body h6  {font-size: 1.5rem;}
.normal-plans p, .pro-plans p {font-size: 14px;}
.primary {     width: 310px;}

/*------- pricing page page end ------*/

/*------- contact page start ----------*/

.contact-banner-content h1 {font-size: 3rem;max-width: 380px;}
.contact-blocker .card-body {padding: 35px 20px;}
.contact-info p{font-size: 15px;}
.question-blocker {margin-top: 60px; padding-bottom: 170px;}
.question-body p {font-size: 15px; max-width: 288px;}
.question-blocker h5 { font-size: 1.06rem;}
.navbar-expand-lg .navbar-nav .nav-link {font-size: 15px !important;}
.contact-banner-content p {max-width: 380px;font-size: 21px;}

/*------- contact page ends ----------*/

}

@media (max-width: 1120px){
.banner-content h1 {max-width: 90%;}
.left-avatar{top: 40px;}

}

@media (max-width: 991.98px) { 
  .graph-body {
    padding: 32px 11px 0px 40px;
  }
  .footer-banner{
  padding: 48px 40px;
}
.footer-banner h2
{
    font-size: 30px;
  }
  .footer-banner h6
  {
      font-size: 18px;
    }

  .banner-content h1 {font-size: 50px;}
  .ring-img {height: 75px;}
  .banner-content p {max-width: 508px;font-size: 19px;}
   h2 {font-size: 35px;font-weight: 600;}
   p{font-size: 17px;}
  .project-card h2 {font-size: 35px;}
  .bussiness-blocker p {line-height: 29px;}
  .left-avatar {height: 80px;}
  .right-avatar { height: 80px;}
  .project-card {max-height: 630px;}
  .inner-tips p{font-size: 15px;}
  .client-service-card {max-height: 323px;}
  .bussiness-blocker { margin-top: 40px;}
  .graph-body {max-height: 450px;}
  .notification-body { max-height: 450px;}
  .client-service-card{margin-top: 27px;}
  .client-service-card {max-height: 256px;}
  /* .features-background {height: 600px;} */
  .features-text h2 {padding-bottom: 15px;font-size: 35px;}
  .features-text {padding: 20px;}

  .banner-a {width: 85px;}
  .banner-b {width: 85px;}
  .project-card {max-height: 770px;}
  .client-service-card,.avatar-teamates{margin-top: 40px !important;}
  .features-blocker {margin-top: 35px;}
   nav .menu{display: flex; justify-content: center;}
  .navbar-nav li{text-align: center;}
  /* .features-background { background-image: url(./Assets/Images/projectflow-res.jpg);background-size: cover;height: 290px;background-repeat: no-repeat;width: 100%;} */
  .features-text{display: flex;justify-content: center;align-items: center;height: 100%;}
  .project-card img {width: 230%;}
  .navbar-toggler:focus {box-shadow: 0 0 0 .10rem !important;}
  .navbar-nav .nav-link:hover {color: #0059D1 !important;font-weight: 500;}


/*------- solutions page start -------*/

.solution-banner-content h1 { font-size: 2.5rem;}
.solution-banner-wrapper{margin-top:30px;}
.subscription-types ul li {font-size: 9px;}
.subscription-types .material-symbols-outlined {font-size: 15px;}
.get-started .inner-tips p {font-size: 12px;font-weight: 500;}
.get-started .card-body h2 {font-size: 2.2rem;}
.get-started p { font-size: 14px;}
.main-features-content h2 {font-size: 2.2rem;}
.main-features-content p {max-width: 599px;margin-bottom: 35px;}
.main-features-blocker{display: flex;justify-content: start;text-align: start;  }
.main-features-blocker h3 {font-size: 2rem;max-width: 100%;margin: auto;}
.main-features-blocker p {max-width: 670px;line-height: 30px;}
/* .main-features img{display: inherit;margin: auto;} */
.promotion-blocker {margin-top: 15px;}
.promotion-body{background-image: none;background-color: #F2FBFF;text-align: center;}
.promotion-body h3 {font-size: 2rem;}
.promotion-body p { max-width: 525px;margin: auto;}
.support-blocker { margin-bottom: 150px;}
.solution-banner-content p {font-size: 19px;}
/* .timeline{display: flex;justify-content: center;} */
.get-started {margin-top: 46px;}

/*------- solutions page end --------*/

/*------- pricing page page start-------*/

.pricing-banner-content h1 {font-size: 2.5rem;}
.plantypes-content h2{font-size: 2.4rem;}
.normal-plans .card-body h5, .pro-plans .card-body h5 { font-size: 2.3rem;}
.normal-plans .card-body h6, .pro-plans .card-body h6 {font-size: 2rem;}
.normal-plans p, .pro-plans p {font-size: 20px;}
.plan-divider-text h2{font-size: 2.2rem;}
.table td, .table th {padding: 7px 0px;font-size: 14px;}
.plan-divider{margin-bottom: 170px;}
.table .material-symbols-outlined { font-size: 19px;}
/* .pricing-banner-wrapper {margin-top: 40px;} */
.pricing-banner-content p {font-size: 19px;}


/*------- pricing page page end ------*/


/*------- contact page start ----------*/

/* .contact-banner-wrapper{margin-top: 50px;} */
.form-body{margin-top: 45px;}
.contact-banner-content h1 {font-size: 2.7rem;max-width: 100%;}
.question-blocker h5 {font-size: 1.2rem;}
.form-img { width: 100%;}
.contact-banner-content p {font-size: 19px;}

/*------- contact page ends ----------*/

}

@media (max-width: 767.98px) { 
.pricing-bg{
      height: 303px;
      padding-top: 45px;
    }
.footer-menu h6{
  text-align: center;
}
.footer-menu ul li{
  padding-bottom: 1px;
}
.product{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.pld{
  text-align: center;
}
  .graph-body {padding: 85px 11px 0px 40px;}

.project-card h2 {font-size: 30px;}
.ring-img {height: 80px;}
.left-avatar { height: 68px;}
.right-avatar{height: 60px;}
.project-card h2 {font-size: 28px;}
.banner-content p {max-width: 380px;font-size: 17px;}
.btn-primary {padding: 10px 25px;}
.bussiness-blocker h2 {font-size: 28px;}
 p{font-size: 17px;}
.project-card {max-height: 590px;}
.graph-body {max-height: 400px;}
.ring-img {height: 60px;}
.banner-content small {letter-spacing: 2px;font-size: 18px;}
 footer h2 { font-size: 28px;}
.bussiness-blocker p {line-height: 27px;}
.banner-content h1 {font-size: 40px;}
.footer-menu,.footer-menu-polices{display: block;}
.footer-menu li,.footer-menu-polices li{display: flex; justify-content: center;padding: 10px;}
.bottom-menu img{display: flex;justify-content: center;}
.footer-logo{display: flex; justify-content: center;}
.footer-icons ul{justify-content: center !important;}

.banner-a {width: 75px;}
.banner-b {width: 80px;}
.notification-body { max-height: 400px;}
.graph-body { max-height: 390px;}
.nav-style .navbar-brand img { max-width: 180px;}
.footer-banner {margin-top: -100px;}
.support-blocker {margin-bottom: 120px;}
.features-text h2 {font-size: 28px}
/* .features-background{height: 240px;} */
.features-blocker {margin-bottom: 160px;}
.footer-logo img { width: 60% !important;margin: auto;}

/*------ solutions page start -------*/

.solution-banner-content h1 {font-size: 2.6rem; text-align: center; max-width: 330px; margin: auto;}
.subscription-types ul li {font-size: 10px;}
.solution-banner-content p {max-width: 100%;text-align: center;}
.solution-banner-content .btn-blocker{text-align: center;}
.get-started .card-body h2 {font-size: 1.8rem;}
.main-features {margin-top: 25px;}
.main-features-content p {max-width: 90%;}
.main-features-blocker h3 {font-size: 1.5rem;}
.main-features-blocker p {max-width: 100%;line-height: 28px;}
.promotion-body {height: 300px;padding: 20px;}
.solution-banner-content{margin-top: 20px;}
.main-features-content h2 { font-size: 1.8rem;}
.solution-banner-content p {font-size: 17px;padding-top: 10px;}

/*------- solutions page end --------*/

/*------- pricing page page start-------*/

/* .pricing-banner-wrapper { margin-top: 20px;} */
.pricing-banner-content h1 {font-size: 2.5rem;max-width: 440px; margin: auto;padding-top: 30px;}
.pricing-banner-content p {max-width: 440px;margin: auto;padding-top: 15px;}
.pricing-banner-content{text-align: center;}
.plantypes-content h2 {font-size: 2rem; margin: auto;}
.plan-divider-text h2 {font-size: 2rem;max-width: 460px; margin: auto;}
.pricing-banner-content p {font-size: 17px;}
/* .primary {width: 50%;} */

/*------- pricing page page end ------*/

/*------- contact page start ----------*/

.contact-banner-content h1 {font-size: 2.5rem;max-width: 85%; margin: auto;text-align: center; padding-top: 25px;}
.contact-banner-content p {max-width: 393px;margin: auto !important;text-align: center;padding-top: 13px;font-size: 17px;}
/* .contact-banner-wrapper { margin-top: 20px;} */
.question-head h2 {font-size: 2.2rem;}
.question-head p { max-width: 408px;}
.question-blocker h5 {font-size: 1.1rem;max-height: 30px;min-height: 35px;}
.question-blocker {padding-bottom: 150px; padding-right: 13px; padding-left: 13px;}

/*------- contact page ends ----------*/

}

@media (max-width: 575.98px) 
{
  .pricingimgb.img-fluid,
  .pricingimga.img-fluid{
      display: none;
    }
    .pricing-bg{
    height: auto;
    padding-top: 0px;
  }
  .footer-banner h2
  {
      font-size: 25px;
    }
  .graph-body {max-height: 292px;padding: 25px 11px 0px 40px;}
  .banner-content h1 {font-size: 2.1rem;font-weight: 900;}
  .ring-img { height: 48px;}
  .banner-content p {max-width: 283px;}
  .left-avatar {height: 51px;top: 77px; }
  .btn-blocker{display: flex;justify-content: center;margin-top: 10px;}
  .banner-input{display: block;}
  .inner-tips p {font-size: 12px;font-weight: 600;}
  .notification-body {max-height: 345px; }
  .bussiness-blocker h2 { font-size: 26px;}
  .features-text h2 {padding-bottom: 10px;font-size: 24px;}
  /* .features-background {height: 420px;} */
   footer h2 {font-size: 20px;}
  .banner-a {width: 60px;}
  .banner-b {width: 70px;}

  .features-blocker { margin-top: 25px;}
  .project-card {padding: 0px;}
   p{font-size: 14px;}
  .demo-modal h4 {font-size: 19px;}
  .banner-content p { max-width: 415px;font-size: 15px;}
  .banner-input input {margin-top: 35px;}
  .right-avatar {height: 60px;top: 10px;}
  .banner-content small { letter-spacing: 1px;font-size: 14px;}
  .project-card {max-height: 620px;}
  /* .features-background {height: 225px;} */
  .bussiness-blocker p {line-height: 25px;}
  .project-card img {width: 225%;}
  .features-blocker {margin-bottom: 150px;}
  .banner-input label{max-width: 300px; margin: auto;}
 

/*------ solutions page start -------*/

.solution-banner-content h1 { font-size: 2rem;}
.get-started {margin-top: 40px;}
.get-started .card-body h2 {font-size: 1.6rem;}
.main-features-content h2 {font-size: 1.6rem;}
.promotion-body p {max-width: 425px;}
.promotion-body{height: 260px;}
.main-features-blocker h3 {font-size: 1.6rem;}
.support-blocker {margin-top: 30px; margin-bottom: 110px;}
.promotion-blocker {margin-top: 15px;}
.subscription-types ul li {font-size: 8px;}
.main-features-content p { max-width: 100%; margin-bottom: 15px;}
.solution-banner-content p {font-size: 15px;}


/*------- solutions page end --------*/

/*------- pricing page page start-------*/

.plantypes-content { margin-top: 40px;}
.plan-divider {margin-bottom: 140px;}
.plan-divider {margin-top: 40px;}
.pricing-banner-content h1 {font-size: 2rem;max-width: 465px;}
.normal-plans .card-body h5, .pro-plans .card-body h5 {font-size: 1.5rem;}
.normal-plans .card-body h6, .pro-plans .card-body h6 {font-size: 1.7rem;font-weight: 500;}
.animation-plan {height: 70px;}
.normal-plans p, .pro-plans p {font-size: 19px;}
.normal-plans,.pro-plans{padding: 10px;}
.plan-divider-text h2 {font-size: 1.8rem; max-width: 390px;}
.plantypes-content h2{
   font-size: 25px;
    max-width: none;
  }
.pricing-banner-content p {font-size: 15px;}


/*------- pricing page page end ------*/

/*------- contact page start ----------*/

.contact-banner-content h1 {font-size: 2rem;}
.question-body{display: flex;justify-content: center;text-align: start;}
.question-body p{max-width: 100%;font-weight: 300;}
.question-body {margin-bottom: 5px;}
.form-body h3 {font-size: 1.5rem;}
.question-head h2 {font-size: 1.7rem;}
.question-head {margin-bottom: 40px;}
.question-blocker h5 {font-size: 1.2rem;min-height: 20px;font-weight: 500;}
.question-head p {max-width: 95%;}
.contact-banner-content p {font-size: 15px;}
.custom-field.one input + .placeholder, .custom-field.one textarea + .placeholder {font-size: 14px;}

/*------- contact page ends ----------*/

}

@media (max-width: 429.98px) {
  .graph-body {
    max-height: 260px;
    padding: 25px 11px 0px 40px;
  }
  .footer-banner h2{font-size: 22px;}

}

@media   (max-width: 548px)
{
  /* .primary {
    width: 65%;
  } */
}

@media (max-width: 390px) {
  .footer-banner h2
  {
      font-size: 18px;
    }
    .footer-banner h6
    {
        font-size: 15px;
      }
  .graph-body {max-height: 215px;}
  .banner-content h1 { font-size: 2rem;}
  .right-avatar {height: 50px;}
  .left-avatar{ height: 45px;top: 81px;}
  .project-card h2 {font-size: 21px; }
  .inner-tips p {font-size: 10px;padding: 7px 5px}
  .bussiness-blocker h2 {font-size: 21px;}
  .features-text h2 {padding-bottom: 10px;font-size: 21px;}
  /* .features-background { height: 210px; } */
   footer h2 {font-size: 21px;}
  .notification-body {max-height: 275px;}
  .project-card img {width:250%;}
  .project-card {max-height: 455px;}

  .banner-a { width: 50px; }
  .banner-b { width: 60px;}
  footer h2 {font-size: 18px;}
  .features-blocker {margin-bottom: 150px;}
  .right-avatar {top: 5px;}
  .ring-img {height: 48px;}
  .banner-content p { font-size: 15px;max-width: 95%;}

/*------ solutions page start -------*/

.get-started .card-body h2 {font-size: 1.5rem;}
.main-features-content h2 {font-size: 1.5rem;padding-top: 10px;}
.main-features-blocker p {line-height: 25px;}
.promotion-body h3 {font-size: 1.2rem;}
.promotion-body {height: 200px;}
.subscription-types .material-symbols-outlined {font-size: 14px;}
.subscription-types ul li { font-size: 7px;}
.solution-banner-content p { max-width: 95%;margin: auto;}

/*------- solutions page end --------*/

/*------- pricing page page start-------*/

.plantypes-content h2 {font-size: 1.5rem;max-width: 249px;}
.plan-divider-text h2 {font-size: 1.5rem;max-width: 270px;}
.table td, .table th {font-size: 13px;}
.normal-plans p, .pro-plans p {font-size: 16px;}
.normal-plans,.pro-plans{padding: 0px;}
/* .primary {width: 75%;} */

/*------- pricing page page end ------*/

/*------- contact page start ----------*/

.question-head p {max-width: 310px;}
.question-blocker h5 {font-size: 1.2rem;}
.question-head h2 {font-size: 1.5rem;}

/*------- contact page ends ----------*/

}
@media (max-width: 350px) {
  .table td, .table th {
    font-size: 12px;
  }
}
.loader {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line {
    background-color: #5693F8;
    width: 7px;
    height: 40px;
    margin: 0px 5px;
    border: none;
    animation: size 0.8s infinite linear;
    border-radius: 20px;
  }
  .line-1{
    animation-delay: 0.60s;
  }
  .line-2{
    animation-delay: 0.30s;
  }
  .line-3{
    animation-delay: 0.s;
  }
  .line-4{
    animation-delay: 0.30s;
  }
  .line-5{
    animation-delay: 0.60s;
  }
  @keyframes size {
  
    0% {
      height: 40px;
    }
    25% {
      height: 60px;
    }
    50% {
      height: 90px;
    }
    75% {
      height: 60px;
    }
    100% {
      height: 40px;
    }
  
  }
  